import sizes from "../scss/_settings/_settings.scss";

window.sizes = {};
Object.keys(sizes).map(function(key){
  window.sizes[key] = parseInt(sizes[key]);
});

window.Template = require('./lib/template.js');

window.time = () => (+ new Date())/1000;

require('./parts/common.js');
require('./parts/click.js');
require('./parts/filters.js');
require('./parts/loading.js');
require('./parts/search.js');

require('../scss/common.scss');
require('../fonts/Tinos/face.css');
require('../fonts/Mitias/face.css');
