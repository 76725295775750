
// a.href linker
$.fn.link = function (e) {
    return $('[data-target="'+this.data('link')+'"]');
};

// Hrefs linker on hover
$(document).on('mouseover', '[data-link]', function () {
    $(this).link().addClass('hover');
});

$(document).on('mouseout', '[data-link]', function () {
    $(this).link().removeClass('hover');
});

// Click or Drag events:
window.touch_ = false;
window.is_drag_ = false;

$(document).on('touchstart mousedown', function(e) {
    window.is_drag_ = false;
    window.touch_ = { x: e.pageX, y: e.pageY };
});

$(document).on('touchend mouseup', function(e) {
    let dx = Math.abs(window.touch_.x - e.pageX);
    let dy = Math.abs(window.touch_.y - e.pageY);
    if (dy + dy > 16) window.is_drag_ = true;
    console.log('window.is_drag_ = ' + window.is_drag_);

    setTimeout(function () {
        window.touch_ = false;
    }, 10);
});

/*
document.body.addEventListener('touchmove', function (e) {
    let dx = Math.abs(window.touch_.x - e.pageX);
    let dy = Math.abs(window.touch_.y - e.pageY);
    if (dx > dy) e.preventDefault();
}, { passive: false });
*/

$(document).on('click', '[data-link]', function () {
    let to = $(this).link().attr('href');
    if (to && !window.is_drag_) location.href = $(this).link().attr('href');
});


