window.loadMediaQueue = [];

window.isResolving = false;

window.isSafari = false;

window.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
window.isIE = /*@cc_on!@*/false || !!document.documentMode;

window.webp = function(src){
  if (isSafari || isIE) return src;
  if (src.indexOf('.jpg') !== -1) return src.replace('.jpg', '.webp');
  if (src.indexOf('.png') !== -1) return src.replace('.png', '.webp');
  return src;
};

window.resolveQueue = () => {
  let is_loading = false;

  window.isResolving = setInterval(() => {
    if (loadMediaQueue.length === 0) {
      clearInterval(window.isResolving);
      window.isResolving = false;
      return;
    }

    if (is_loading) return;
    is_loading = true;

    let item = loadMediaQueue.pop();
    let e = new Image();
    e.onload = () => {
      item.target.css('backgroundImage', 'url("' + window.webp(item.src) + '")');
      is_loading = false;
      console.log('Loaded', item.src);
      setTimeout(function () { item.target.addClass('bg-loaded'); }, 50);
    };
    e.src = item.src;
  }, 20);
};

window.loadMedia = (parent) => {
  console.log(parent);

  // -> Insert SVG
  $('[data-preload]').each(function () {
    let name = $(this).data('preload');
    let html = $(this).html();
    $('[data-'+name+'-paste]').each(function () {
      for (let i = parseInt($(this).data(name + '-paste')) || 1; i > 0; i--){
        $(this).append(html.replace('class="', 'class="item-'+i+' '));
      }
    });
  });

  // -> Set Bottle Colors
  parent.find('[data-bottle-color]').each(function () {
    let color = $(this).data('bottle-color');
    $(this).find('.color-line path, .emotion .back, .map .figure').css('fill', color);
  });

  let retina = (window.retina || window.devicePixelRatio > 1) || $(window).width() > sizes.M;

  let i = 0;
  parent.find('[data-bg-after]').each(function () {
    let src = $(this).data('bg-after')
    if (retina && $(this).data('large')) src = $(this).data('large');
    loadMediaQueue.push({
      priority: ($(this).data('priority') || 999) + (i++),
      src: src,
      target: $(this)
    });
  });

  loadMediaQueue.sort((a, b) => { return b.priority - a.priority; });
  if (!window.isResolving) resolveQueue();
};

loadMedia($('body'));

// -> First page load RUN
$(window).on('load', function () {
  if (window.loadAction) return;
  window.loadAction = true;
  loadMedia($('body'));
});
