// Мобильнле меню
window.click_active = false;
$(document).on('click touchstart', '[data-mobile-menu-opener]', function () {
  if (window.click_active) return ;
  window.click_active = true;
  setTimeout(() => { window.click_active = false; }, 600);
  $('[data-mobile-menu], [data-mobile-menu-opener]').toggleClass('opened');
});

// Подписка
$(document).on('click', '[data-subscribe-me]:not(.disabled)', function (e) {
  let f = $(this).closest('[data-subscribe-form]');
  $('[data-subscribe-me]').addClass('disabled');
  $('[data-subscribe-me] .text').text('Отправка ...')
  $.post('/subscribe', {email: f.find('input').val()}, function () {
    f.find('input').val('Ваш адрес добавлен').addClass('disabled').attr('disabled', true);
    $('[data-subscribe-me] .text').text('Вы великолепны!');
  });
});

// On scroll to animated element
window.current_scroll = 0;
$(document).on('scroll', function (e) {
  $('body').attr('data-scroll-up', window.pageYOffset < window.current_scroll);
  $('body').attr('data-scrolled', window.pageYOffset > 200);

  window.current_scroll = window.pageYOffset;
  const vis = window.pageYOffset + $(window).height();
  $('[data-onvisible]').each(function (){
    if (vis - (parseInt($(this).data('onvisible')) || 0) > $(this).offset().top) {
      $(this).addClass('scroll-to');
      $(this).trigger('scroll-to');
    }
  });
});

$(document).on('click', '[data-age-reset]', function (e) {
  location.href = 'https://www.google.com/search?q=%D0%B2%D0%B8%D0%BD%D0%BE%D1%88%D0%BD%D0%B0%D1%8F';
});

function is_frame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

if ($('.age-confirm').length > 0) $('body,html').css({ overflow: 'hidden' });

if (is_frame()) {
  $('.age-confirm').remove();
  $('body,html').css({ overflow: 'auto' });
}

$(document).on('click', '[data-age-confirm]', function (e) {
  document.cookie = 'showAge=true; max-age=' + (999 * 24 * 60 * 60);
  $('.age-confirm').addClass('hide');
  $('body,html').css({ overflow: 'auto' });
  setTimeout(() => {
    $('.age-confirm').remove();
  }, 350);
});

// Cookie-bar
let age_active = setInterval(() => {
  if ($('[data-age-confirm]').length > 0) return ;
  clearInterval(age_active);

  setTimeout(function () {
    if (localStorage && localStorage.getItem('cookie') === 'Ok') return ;
    $('body').removeClass('cookie-bar-invisible');
    $('[data-cookie-ok]').click(function () {
      if (localStorage) localStorage.setItem('cookie', 'Ok');
      $('body').addClass('cookie-bar-invisible');
    });
  }, 3000);
}, 100);

setTimeout(function () {
  $(window).toggle('resize');
}, 30);

$('[data-rate]').on('scroll-to', function () {
  let rate = parseFloat($(this).data('rate'));
  let that = $(this);
  setTimeout(function () {
    for (let i in [0,1,2,3,4]) {
      let val = Math.min(18, Math.max((rate - i) * 18, 0));
      that.find('[data-i="'+i+'"] img.wave').css({top: 18 - val - 0.2});
    }
  }, 450);
});

