let num2word = function($num, words){
  let cases = [2, 0, 1, 1, 1, 2];
  return $num + ' ' + words[($num % 100 > 4 && $num % 100 < 20) ? 2 : cases[Math.min($num % 10, 5)]];
};

let bottles = $('[data-filters]').data('filters');

let intersection = function(a, b) {
  a = a.map(e => parseInt(e));
  b = b.map(e => parseInt(e));
  return {
    i: Math.min(a[1], b[1]) - Math.max(a[0], b[0]),
    a: a[1] - a[0],
    b: b[1] - b[0],
  };
};

let this_price = function (element, price) {
  if (!element || element.length === 0) return true;
  let a = element.data('f-price').split(',');
  let its = intersection(a, price);
  let res = false;
  if (its.i > 0 && (its.i > its.a/2 || its.i > its.b/2)) res = true;
  return res ;
};

window.counter_note = null;

let apply = function(id_default){
  let moods = {};

  $('[data-f-mood].active').each(function () {
    moods[$(this).data('f-mood') + ''] = true;
  });

  moods = Object.keys(moods);
  console.log('moods', moods);

  $('[data-filters] [data-check]').addClass('disabled');

  let filtred = bottles.filter(function (item) {
    for (let i in moods) if (item.mood.indexOf(moods[i]) === -1) return false;
    $('[data-f-type="'+item.type+'"]').removeClass('disabled');
    $('[data-f-taste="'+item.taste+'"]').removeClass('disabled');
    $('[data-f-price]').each(function () {
      if (this_price($(this), item.price)) $(this).removeClass('disabled');
    });
    return true;
  });

  let type  = $('[data-f-type].selected:not(.disabled):first').data('f-type');
  let taste = $('[data-f-taste].selected:not(.disabled):first').data('f-taste');
  let price = $('[data-f-price].selected:not(.disabled):first');

  filtred = filtred.filter(function (item) {
    if (type && item.type != type) return false;
    if (taste && item.taste != taste) return false;
    if (price.length && !this_price(price, item.price)) return false;
    return true;
  });

  let message = '';
  if (filtred.length === 0) {
    $('[data-results-text]').html('Будьте скромнее! Таких вин не бывает!');
    $('[data-results]').addClass('disabled');
    $('[data-results]').attr('href', '')
    message = 'Таких вин нет!';
  } else {
    $('[data-results]').removeClass('disabled');
    if (filtred.length === 1) {
      $('[data-results-text]').html('У нас есть для вас отличная бутылочка!');
      $('[data-results]').attr('href', filtred[0].url);
      message = '1 бутылочка';
    } else {
      let btx = num2word(filtred.length, ['отличная бутылочка', 'отличные бутылочки', 'отличных бутылочек']);
      $('[data-results-text]').html('У нас есть '+btx+' на ваш вкус!');
      message = num2word(filtred.length, ['бутылочка', 'бутылочки', 'бутылочек']);

      let params = [];
      if (moods.length > 0) params.push('moods=' + moods.join(','));
      if (type) params.push('type=' + type);
      if (taste) params.push('taste=' + taste);
      if (price.data('f-price')) params.push('price=' + price.data('f-price'));

      $('[data-results]').attr('href', '/podborka?' + params.join('&'));
    }
  }

  let one_term = $('[data-f-url]:not(.disabled).active, [data-f-url].selected:not(.disabled)');
  if (one_term.length === 1 && one_term.data('f-url').length > 0 && filtred.length !== 1) {
    $('[data-results]').attr('href', one_term.data('f-url'));
    console.log('+1')
  }

  if (!id_default) {
    console.log('!id_default == true [setting]', [moods, type, taste]);
    localStorage.setItem('time', Math.round((+ new Date())/1000));
    localStorage.setItem('moods', moods.join(','));
    localStorage.setItem('type',  type);
    localStorage.setItem('taste', taste);
    localStorage.setItem('price', price.data('f-price'));

    if (window.counter_note) note_hide(window.counter_note);

    if ($(window).height() + window.pageYOffset < $('[data-results]').offset().top) {
      $('.quick-note').addClass('hide');
      const note = $('<div class="quick-note">' + message + '</div>');
      note.insertBefore('[data-filters]');
      setTimeout(() => note.addClass('show'), 100);
      setTimeout(() => note.addClass('hide'), 6000);
      setTimeout(() => note.remove(), 6350);
    }
  }
};

$('.find-filters').on('scroll', function () {
  $('.quick-note').remove();
});

$(document).on('click', '[data-f-mood]', function () {
  const ID = $(this).attr('data-f-mood');
  $(`[data-f-mood="${ID}"]`).toggleClass('active');
  apply();
});

$(document).on('click', '[data-check]:not(.disabled)', function () {
  console.log('!!!');
  if ($(this).hasClass('selected')) {
    $(this).removeClass('selected');
  } else {
    $(this).parent().find('[data-check]').removeClass('selected');
    $(this).addClass('selected');
  }
  apply();
});

$(document).on('click', '[data-results].disabled', function (e) {
  e.preventDefault();
});


const t = localStorage.getItem('time') || 0;
const now = Math.round((+ new Date())/1000);

if (now - t < 60 * 60) {
  console.log('Diff: ', now - t);

  (localStorage.getItem('moods') || '-1').split(',').map(function (moodID) {
    $('[data-f-mood="'+moodID+'"]').addClass('active');
  });

  $('[data-f-type="'+(localStorage.getItem('type') || '-1')+'"]').addClass('selected');
  $('[data-f-taste="'+(localStorage.getItem('taste') || '-1')+'"]').addClass('selected');
  $('[data-f-price="'+(localStorage.getItem('price') || '-1')+'"]').addClass('selected');
}

apply('default');

$(document).on('click', '[data-open-moods]', function (e) {
  if ($('body').hasClass('moods-opened')) return;
  $('[data-open-moods] .text').html($('[data-moods-list]').clone());
  $('body').addClass('moods-opened');
  setTimeout(function () {
    $('body').addClass('moods-animated');
  }, 100);
});

$(document).on('click', '[data-opener]', function (e) {
  let open = $(this).data('opener') + '-opened';
  let animate = $(this).data('opener') + '-animated';

  console.log('Classes: ');
  console.log([open, animate]);

  if ($('body').hasClass(open)) {
    $('body').removeClass(animate);
    setTimeout(function () { $('body').removeClass(open); }, 450);
  } else {
    $('body').addClass(open);
    $('body').addClass(animate);
  }
});

(location.href.split('?')[1] || '').split('&').map(param => {
  if (param.indexOf('=') === -1) return;
  let p = param.split('=');
  $(`[data-name="${p[0]}"] [data-check="${p[1]}"]`).click();
});
