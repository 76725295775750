// Поиск
window.saved = '';

$(document).on('focus', '[data-find-in]', function (e) {
  $('[data-find-wine]').addClass('activated');
  if (window.saved.length > 0) $('[data-find-in]').val(window.saved);
});

$(document).on('click', '[data-find-wine] .default', function (e) {
  $('[data-find-wine]').addClass('activated');
  if (window.saved.length > 0) $('[data-find-in]').val(window.saved);
});

$(document).on('blur', '[data-find-in]', function (e) {
  return ;
  setTimeout(() => {
    $('[data-find-wine]').removeClass('activated');
    window.saved = $('[data-find-in]').val();
    $('[data-find-in]').val('');
  }, 150);
});

$(document).on('click', '[data-find-close]', function (e) {
  setTimeout(() => {
    $('[data-find-wine]').removeClass('activated');
    $('[data-find-in]').val('');
    window.saved = '';
  }, 50);
});


let s_timer = null;
let s_request = null;

$(document).on('keyup change', '[data-find-in]', function (e) {
  if (s_timer) clearTimeout(s_timer);
  if (s_request && s_request.abort) s_request.abort();

  s_timer = setTimeout(() => {
    const req = $(this).val();

    if (req.length === 0) {
      $('[data-find-results]').html('');
    }

    s_request = $.post('/search', {text: req}, (res) => {
      console.log(res.map(e => e.title).join('\n'));

      if (res.length > 0) {
        $('[data-find-results]').html(
          '<div class="space" />' +
          Template('result-template', res)
        );
      } else {
        if (req.length > 0) {
          const all = $('.link.all-wines').attr('href');
          $('[data-find-results]').html(
            Template('result-fail-template', {'all': all})
          );
        }
      }
    }, "json");

  }, 350);
});
